<template>
  <div class="more-music" v-if="true">
    <div class="more-header">
      <h2 class="header-left">
        <a href="/">Whispering Softly</a>
      </h2>
    </div>
    <div class="more-container">
      <div class="stage1" v-if="stage === 1">
        <p>升级后可访问额外的15种网页音效。</p>
        <p>您还将获得未来发布的任何新网页音效的访问权限。</p>
        <div class="previewSounds">
          <ul>
            <li
              class="soundItem"
              :class="{ active: currentPlay === x }"
              v-for="(i, x) in audioList"
              :key="x"
              @click="playAudio(i, x)"
            >
              <p class="label">{{ i.name }}</p>
              <div class="previewButton">
                <button class="button">
                  <div class="previewInnerContainer">
                    <div class="previewInner"></div>
                  </div>
                </button>
              </div>
            </li>
          </ul>

          <audio
            v-if="currentUrl"
            :src="currentUrl"
            autoplay
            @ended="onAudioEnded"
          ></audio>
        </div>
        <div class="continueContainer">
          <button class="button cancel" @click="closeEl">关闭</button>
          <button class="button continue" @click="showPay">升级会员</button>
        </div>
      </div>

      <div class="stage2" v-else-if="stage === 2 && payData">
        <p>{{ payData.name || "白噪声会员" }}的费用为</p>
        <p class="price">
          {{ `${payData.goods_price || "--"} ${payData.unit}` }}
          <span class="currencyMessage" v-if="payData.price_unit">/ {{ payData.price_unit }}</span>
        </p>
        <p class="currencyMessage">（或等值当地货币）</p>

        <div class="continueContainer">
          <button class="button cancel" @click="closeEl">关闭</button>
          <button class="button continue" @click="stage = 3">支付</button>
        </div>
      </div>

      <div class="stage3" v-if="payData && stage === 3 && userinfo">
        <Pay :payData="payData" :userInfo="userinfo" @close="closeEl" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetAudioList } from '@/api/index'
import { GOODS_LIST, } from '@/api/pay'
import Pay from './pay.vue'
export default {
  components: { Pay },
  name: 'MoreMusic',
  data() {
    return {
      audioList: [],
      currentPlay: -1,
      stage: 1,
      payData: null,
      userinfo: {}
    }
  },
  watch: {
    '$store.state.payShowType': {
      handler(val) {
        this.stage = val || 1
      },
      immediate: true
    },
    '$store.state.userInfo': {
      handler(val) {
        this.userinfo = val
        if (!this.payData?.goods_name) {
          this.getGoodsList()
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    currentUrl() {
      return this.currentPlay >= 0 ? this.audioList[this.currentPlay]?.soundUrl : ''
    }
  },
  created() {
    this.fetchAudioList()
    if (this.userinfo?.id) {
      this.getGoodsList()
    }
  },
  methods: {
    // 获取商品列表
    getGoodsList() {
      const data = {
        system_type: 'all',
        platform_type: 'pc',
        product_type: 'whitenoisepc',
        user_id: this.userinfo?.id || ''
      }
      GOODS_LIST(data).then((res) => {
        if (res.code === 200 && res.data?.viplist) {
          this.payData = res.data?.viplist[0] || {}
        }
      })
    },
    showPay() {
      if (!this.userinfo?.id) {
        this.$message.warning('请先登录')
        this.$store.commit('setLoginShow', true)
        return
      }
      this.stage = 2
    },
    onAudioEnded() {
      this.currentPlay = -1
    },
    playAudio(i, x) {
      this.currentPlay = this.currentPlay === x ? -1 : x
    },
    closeEl() {
      this.currentPlay = -1
      this.$store.commit('setPayShowType', 0)
      this.$emit('MoreShowChange', false)
    },
    async fetchAudioList() {
      try {
        const res = await GetAudioList({ remark: 'pay' })
        if (res.code === 200 && res.data?.list) {
          this.audioList = res.data.list
        }
      } catch (error) {
        console.error('获取音频列表失败:', error)
      }
    },
  }
}
</script>

<style lang='less' scoped>
.more-music {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  color: #000;
  .more-header {
    width: 100%;
    padding: 0.16rem 0.32rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .header-left {
      font-size: 0.32rem;
      font-weight: bold;
      height: 0.64rem;
      line-height: 0.64rem;
      > a {
        color: #000;
      }
    }
  }

  .more-container {
    justify-content: center;
    align-items: flex-start;
    padding: 0.32rem;
    font-size: 0.2rem;
    display: flex;
    overflow-y: auto;
    p {
      margin-bottom: 0.16rem;
      line-height: 1.4;
    }
    .stage1 {
      width: 100%;
      max-width: 1070px;
      max-height: 100%;
      padding: 0.16rem;
      p {
        text-align: center;
      }
      audio {
        position: absolute;
        left: 0;
      }
      .previewSounds ul {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        display: flex;
        .soundItem {
          box-sizing: border-box;
          border: 2px solid #000;
          border-radius: 0.04rem;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          width: calc(25% - 0.32rem);
          margin: 0.16rem;
          padding: 0.16rem;
          font-size: 0.14rem;
          display: flex;
          transition: background 0.1s, color 0.1s;
          position: relative;
          user-select: none;
          cursor: pointer;
          &:hover {
            background-color: #f5f5f5;
          }
          .label {
            user-select: none;
            flex: 1;
            order: 2;
            margin-bottom: 0;
            line-height: 0.32rem;
          }

          .previewButton {
            order: 3;
            width: 0.32rem;
            height: 0.32rem;
            margin: 0 0.08rem;
            position: relative;
            .button {
              width: 0.32rem;
              height: 0.32rem;
            }
            .previewInnerContainer {
              background: #1e1e1e;
              border-radius: 0.16rem;
              justify-content: center;
              align-items: center;
              width: 0.32rem;
              height: 0.32rem;
              display: flex;
            }
            .previewInner {
              content: "";
              border: 0.06rem solid transparent;
              border-left: 0.1rem solid #fff;
              border-right-width: 0;
              width: 0;
              height: 0;
              margin-right: -0.02rem;
            }
          }
        }
        .active {
          background-color: #d7ffd7;
          &:hover {
            background-color: #d7ffd7;
          }
          .previewInnerContainer .previewInner {
            background-color: #d7d7d7;
            border: 0.04rem solid #fff;
            margin-right: 0;
          }
        }
      }

      .continueContainer {
        width: 6.4rem;
        max-width: 90%;
        margin-top: 0.32rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .stage2 {
      width: 6.4rem;
      max-width: 100%;
      p {
        text-align: center;
      }
      .price {
        font-size: 150%;
      }
      .currencyMessage {
        font-size: 0.18rem;
      }
    }
    .stage3 {
      width: 6.4rem;
      max-width: 100%;
      p {
        text-align: center;
      }
    }
  }
  .continueContainer {
    flex: 1;
    justify-content: space-between;
    display: flex;
  }
  .continue.button,
  .cancel.button {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0.04rem;
    margin: 0.16rem 0;
    padding: 0.16rem 0.8rem;
    font-weight: 700;
    background-color: #969696;
  }
  .continue.button {
    background-color: #009600;
  }
}
</style>