<template>
  <div class="user-modal">
    <div class="inner">
      <div class="header">
        <h2>我的资料</h2>
        <button class="cornerClose hoverOpacity" @click="close">×</button>
      </div>
      <div class="content">
        <div class="accountViewContent">
          <table>
            <tr>
              <td class="label">手机:</td>
              <td class="content">{{ userInfo.phone || "" }}</td>
            </tr>
            <tr>
              <td class="label">会员:</td>
              <td class="content" v-if="userInfo && userInfo.user_type === '会员'">
                {{ userInfo.vip_time | dateFormat }}
                <button class="upgrade hoverOpacity renew" @click="toPay">续费</button>
              </td>
              <td class="content" v-else>
                无 -
                <button class="upgrade hoverOpacity" @click="toPay">去升级</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="buttonContainer">
        <button class="button submit" @click="close">关闭</button
        ><button class="button cancel" @click="loginOut">退出登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/helper'
export default {
  name: 'UserModal',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userModal: true,
      userInfo: {}
    }
  },
  filters: {
    dateFormat(time) {
      return formatDate(time)
    }
  },
  watch: {
    info: {
      handler(val) {
        this.userInfo = val
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.userInfo = this.info
  },
  methods: {
    toPay() {
      this.$store.commit('setPayShowType', 2)
    },
    loginOut() {
      this.$emit('loginOut')
    },
    close() {
      this.$emit('modalClose')
    }
  }
}
</script>

<style lang='less' scoped>
.user-modal {
  z-index: 999;
  color: #000;
  background: rgba(0, 0, 0, 0.8);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .inner {
    background: #fff;
    border-radius: 0.04rem;
    width: 6.4rem;
    max-width: 90%;
    margin-top: -1.28rem;
    padding: 0.24rem 0.32rem;
  }
  h2 {
    margin-bottom: 0.16rem;
    font-size: 0.32rem;
  }
  button {
    color: #fff;
    border-radius: 0.04rem;
    padding: 0.08rem 0.16rem;
    font-weight: 700;
  }
  button.submit {
    background: #009600;
  }
  button.cancel {
    background: #e68c32;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    td {
      padding-bottom: 0.12rem;
    }
    .content {
      text-align: left;
    }
    button {
      color: #000;
      margin: 0;
      padding: 0;
      font-weight: 400;
      text-decoration: underline;
    }
  }
  table .label {
    text-align: right;
    padding-right: 0.16rem;
  }
  .header {
    flex-direction: "row";
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 0.24rem;
    display: flex;
    .cornerClose {
      color: #000;
      padding: 0 0 0.08rem 0.08rem;
      font-size: 0.4rem;
      font-weight: 700;
      line-height: 1;
    }
  }
  .buttonContainer {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 0.4rem;
    display: flex;
  }
  .renew {
    margin-left: 0.14rem;
    color: #5a6add;
  }
}
</style>