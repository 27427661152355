
<template>
  <div class="popups-login">
    <main>
      <div class="login-content">
        <i class="popclose" @click="closepop">×</i>
        <div class="login-title">
          <span :class="{ active: type === 1 }" @click="loginTypeChange(1)"
            >微信登录</span
          >
          <span :class="{ active: type === 2 }" @click="loginTypeChange(2)"
            >手机登录</span
          >
        </div>
        <div class="login-left" v-if="type === 1">
          <WxLogin />
          <div class="wx-login-tips">
            <span>使用微信扫码进行登陆</span>
            <!-- <div>
              <el-checkbox v-model="checked"></el-checkbox>
              登录即同意<a href="/agreement/UserAgreement">《用户协议》</a>和<a
                href="/agreement/AiPrivacyAgreement"
                >《隐私政策》</a
              >
            </div> -->

            <div class="login-confirm-info">
              <span
                @click="confirmInfoChange"
                :class="{
                  'login-confirm-info_checkbox_checked': confirmInfo,
                }"
                class="login-confirm-info_checkbox"
                role="checkbox"
                aria-checked="checkbox"
              ></span>
              <span class="login-confirm-info__before-text">登录即同意</span>
              <a href="/agreement/UserAgreement">《用户协议》</a>
              <span class="login-confirm-info__before-text">和</span>
              <a href="/agreement/AiPrivacyAgreement">《隐私政策》</a>
            </div>
          </div>
        </div>
        <div class="login-right" v-else>
          <PhoneLogin type="popups" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import WxLogin from '@/components/login/wx-login.vue'
import PhoneLogin from '@/components/login/phone-login.vue'

export default {
  name: 'PopupsLogin',
  components: { WxLogin, PhoneLogin },
  data() {
    return {
      type: 1,
      code: '',
      confirmInfo: true
    }
  },
  created() {
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflow = 'unset'
    // this.$router.go(0)
  },
  computed: {
    loginTypeTxt() {
      return this.type === 1 ? '微信' : '手机'
    }
  },
  methods: {
    closepop() {
      this.$store.commit('setLoginShow', false)
    },
    loginTypeChange(type) {
      this.type = type
    },
    confirmInfoChange() {
      // 点击勾选同意用户协议事件
      this.confirmInfo = !this.confirmInfo
      this.errmsg = this.confirmInfo ? '' : '请先勾选用户协议和隐私政策'
    },
  }
}
</script>

<style lang='less' scoped>
.popups-login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 9999;
  main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .login-content {
    // width: fit-content;
    width: 4rem;
    min-height: 3.54rem;
    // width: calc(100vw - 0.4rem);
    // margin: 12vh auto 15vh;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.24rem;
    padding: 0.35rem 0.4rem 0.2rem;
    box-shadow: 0px 1px 0.06rem 0.05rem rgba(131, 131, 131, 0.05);
    position: relative;
    .popclose {
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
      font-size: 0.18rem;
      font-weight: bold;
      cursor: pointer;
      color: #333;
      &:hover {
        opacity: 0.8;
      }
    }
    .login-title {
      font-size: 0.2rem;
      font-weight: 600;
      color: #999;
      font-family: PingFangSC-Medium, PingFang SC;
      text-align: center;
      margin-bottom: 0.3rem;
      > span {
        display: inline-block;
        padding: 0 0.2rem;
        padding-bottom: 0.09rem;
        cursor: pointer;
      }
      > span.active {
        color: #333333;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: calc(4em - 0.1rem);
          height: 0.04rem;
          background-color: #333;
          border-radius: 0.02rem;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }
    }
    .login-wx-login {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .login-left {
      width: 100%;
      height: 100%;
      position: relative;
      /deep/ .wx-login {
        height: 1.62rem;
      }
      .wx-login-tips {
        width: 100%;
        text-align: center;
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.14rem;
          font-weight: 400;
          color: #333333;
          padding-bottom: 0.16rem;
          border-bottom: 1px solid #f5f6f9;
          margin-bottom: 0.16rem;
          > i {
            display: block;
            width: 0.25rem;
            height: 0.25rem;
            background-repeat: no-repeat;
            background-image: url("~@/assets/icons/wx_login.png");
            background-size: 100% 100%;
            margin-right: 0.06rem;
          }
        }
        > div {
          font-size: 0.12rem;
          font-weight: 400;
          color: #999999;
          /deep/ .el-checkbox {
            .el-checkbox__inner {
              border-color: #e8e8e9;
            }
            .is-checked .el-checkbox__inner {
              font-weight: 700;
              border-color: #f7ac4f;
              background-color: #f7ac4f;
            }
          }
        }

        .login-confirm-info {
            user-select: none;
            outline: none;
            line-height: 0.2rem;
            font-size: 0.12rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.16rem;
            margin-bottom: 0.06rem;
            .login-confirm-info_checkbox {
              display: inline-block;
              width: 0.14rem;
              height: 0.14rem;
              margin-right: 0.04rem;
              border-radius: 0.02rem;
              border: 1px solid #d9d9d9;
              vertical-align: text-bottom;
              cursor: pointer;
            }
            .login-confirm-info_checkbox_checked {
              border: none;
              background-repeat: no-repeat;
              background-position-x: 50%;
              background-size: 0.14rem 0.14rem;
              background-image: url("~@/assets/icons/checked.png");
            }
            .login-confirm-info__before-text {
              color: #999;
            }
            > a {
              color: #999;
            }
          }
      }
    }
    .login-right {
      flex: 1;
      height: 100%;
      /deep/ .phone-login {
        width: 100%;
        .login-body {
          width: 100%;
          .login-form {
            width: 100%;
            .login-submit-btn-wrapper {
              padding-bottom: 0.3rem;
              border-bottom: 1px solid #f5f6f9;
              button {
                background-color: rgb(255, 85, 51);
              }
              .login-submit-btn-disabled {
                background-color: rgba(255, 85, 51, 0.5);
                cursor: not-allowed;
              }
            }
            .login-confirm-info {
              justify-content: center;
              > span,
              a {
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}
</style>
